import React from 'react'

// Components
import { TitleDefault, TitleAlt, ButtonSecondary } from 'components/Elements'

// CSS
import './CallToAction.scss'

const CallToAction = ({ title, subtitle, buttonLocation, buttonText }) => {
  return (
    <div className="call-to-action color-background-contrast">
      <div className="container py-4">
        <div className="row py-2">
          <div className="col-12 col-lg-8 text-center">
            <TitleDefault className="color-text-light">
              <div
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </TitleDefault>
            <TitleAlt className="color-text-light">
              <div
                dangerouslySetInnerHTML={{
                  __html: subtitle,
                }}
              />
            </TitleAlt>
          </div>
          <div className="col-12 col-lg-4 text-center d-lg-flex justify-content-end align-items-center mt-4 mt-lg-0">
            <ButtonSecondary to={buttonLocation}>
              <div
                dangerouslySetInnerHTML={{
                  __html: buttonText,
                }}
              />
            </ButtonSecondary>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToAction
