import React from 'react'
import BackgroundImage from 'gatsby-background-image'

// Libraries
import { map as _map } from 'lodash'

// Components
import {
  TitleDefault,
  TitleAlt,
  ButtonAltSecondary,
  BoxDefault,
} from 'components/Elements'

// CSS
import './Packages.scss'

const Packages = ({ title, subtitle, buttonText, items }) => {
  return (
    <div className="packages color-background-contrast">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 pr-lg-5 py-4">
            <TitleDefault className="color-text-light">{title}</TitleDefault>
            <div className="text-right">
              <TitleAlt className="color-text-light">{subtitle}</TitleAlt>
            </div>
          </div>
          <div className="packages-options col-12 col-lg-6 mt-5 mt-lg-0 position-relative">
            <div className="row pb-5 pb-lg-0">
              {_map(items, ({ location, image }, index) => (
                <Option
                  key={index}
                  image={image}
                  location={location}
                  buttonText={buttonText}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Option = ({ location, image, buttonText }) => (
  <div className="mt-4 mt-sm-0 col-sm-6">
    <BoxDefault className="h-100">
      <div className="py-2 px-1 px-xl-3 h-100">
        <BackgroundImage
          className="packages-option-image"
          fluid={image.localFile.childImageSharp.fluid}
        />
        <ButtonAltSecondary className="w-100" to={location}>
          <div
            className="text-right"
            dangerouslySetInnerHTML={{ __html: buttonText }}
          />
        </ButtonAltSecondary>
      </div>
    </BoxDefault>
  </div>
)

export default Packages
