import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Libraries
import parse from 'html-react-parser'
import {
  find as _find,
  includes as _includes,
  map as _map,
  size as _size,
  filter as _filter,
  each as _each
} from 'lodash'

// Components
import {
  TitleSubDefault,
  ButtonMore,
  ButtonGoAlt,
  ButtonGoDefault,
  ContentDefault,
  TableDefault,
} from 'components/Elements'

// CSS
import './Searchtable.scss'

const Searchtable = ({ id }) => {
  const {
    allWordpressWpZoektabellen: { edges: tables },
  } = useStaticQuery(graphql`
    {
      allWordpressWpZoektabellen {
        edges {
          node {
            wordpressId: wordpress_id
            acf {
              title
              placeholder: search_placeholder
              moreButton: more_button
              table {
                header {
                  value: c
                }
                body {
                  value: c
                }
              }
              extraDescription: extra_description
            }
          }
        }
      }
    }
  `)

  const {
    node: {
      acf: { title, placeholder, moreButton, table, extraDescription },
    },
  } = _find(tables, ({ node: { wordpressId } }) => wordpressId === id)

  let [timesLimit, setTimesLimit] = useState(1)

  let limit = 10

  limit = limit * timesLimit

  const [filters, setFilters] = useState({
    tempSearch: '',
    search: '',
  })

  const normalizeCharachters = string =>
    string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  const tableHeader = table.header
  let tableBody = table.body

  if (filters.search) {
    const searchValue = normalizeCharachters(filters.search.toLocaleLowerCase())

    tableBody = _filter(tableBody, (items, index) => {
      let filter = false

      _each(items, ({ value }) => {
        if (_includes(normalizeCharachters(value.toLowerCase()), searchValue)) {
          filter = true
        }
      })

      return filter
    })
  }

  let tableBodySize = _size(tableBody)

  if (limit <= _size(tableBody)) {
    tableBody = limit
      ? _filter(tableBody, (edge, index) => index < limit)
      : tableBody
  }

  const hasMore = _size(tableBody) < tableBodySize

  return (
    <div className="searchtable">
      <TitleSubDefault className="text-center font-size-l">
        {title}
      </TitleSubDefault>
      <form
        onSubmit={event => {
          event.preventDefault()
        }}
        className="mt-3 text-center"
      >
        <input
          type="text"
          placeholder={placeholder}
          onChange={e => {
            setFilters({
              ...filters,
              ...{ tempSearch: e.target.value },
            })
          }}
        />
        <button
          type="submit"
          className=""
          onClick={() => {
            setFilters({
              ...filters,
              ...{ search: filters.tempSearch },
            })
          }}
        >
          <ButtonGoAlt />
        </button>
      </form>
      <ContentDefault className="mt-5">
        <TableDefault>
          <thead>
            <tr>
              {_map(tableHeader, ({ value }, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {_map(tableBody, (items, index) => (
              <tr key={index}>
                {_map(items, ({ value }, index) => (
                  <td key={index}>
                    {parse(value, {
                      replace: domNode => {
                        if (domNode.data.substr(0, 4) === 'http') {
                          const Button = () => (
                            <ButtonGoDefault isAnchor to={domNode.data} />
                          )

                          return React.createElement(Button, {}, '')
                        }
                      },
                    })}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </TableDefault>
      </ContentDefault>
      {hasMore && (
        <div className="mt-3 text-center">
          <button
            onClick={() => {
              setTimesLimit((timesLimit += 1))
            }}
          >
            <ButtonMore>{moreButton}</ButtonMore>
          </button>
        </div>
      )}
      {extraDescription && (
        <ContentDefault className="mt-5">
          <div dangerouslySetInnerHTML={{ __html: extraDescription }} />
        </ContentDefault>
      )}
    </div>
  )
}

export default Searchtable
